import axios from 'axios';

// import { removeAdZoneWidget } from '../../hooks/PopUnder/helpers';

export const urls = {
  registerUser: '/api/auth/member/register',
  resetUserPassword: './api/auth/member/forgot-password',
  resetPassword: '/api/auth/member/reset-password',
  updateUser: '/api/users',
  userProfile: '/api/profiles',
  resendEmail: '/api/auth/member/resend',
  unsubscribe: '​/api​/members​/unsubscribe​',
  cancelSubscription: 'api/users/cancel-subscription',
  contact: 'api/users/contact'
};

function deleteAllCookies() {
  var cookies = document.cookie.split(';');

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf('=');
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
}

export const cleanupUserData = () => {
  deleteAllCookies();
  // removeAdZoneWidget();
  return localStorage.removeItem('userData');
};

export const setUserLocalData = (data) => {
  data && localStorage.setItem('userData', JSON.stringify(data.data));
};

export const getUserData = () => {
  if (localStorage.getItem('userData') !== 'undefined')
    return JSON.parse(localStorage.getItem('userData'));
};

export const getLoggedUsers = (data) => {
  return JSON.parse(localStorage.getItem(data));
};

export const getUserProfile = (id) => {
  return axios.get(`${urls.userProfile}/${id}`);
};

export const saveUserProfile = (id, data, userToken) => {
  return axios.post(
    `${urls.userProfile}/${id}`,
    {
      ...data,
      _method: 'PATCH'
    },
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  );
};

export const forgotPassword = (email) => {
  return axios.post(urls.resetUserPassword, {
    email: email
  });
};

export const updateUser = (profile) => {
  return axios.patch(urls.updateUser, profile, {
    headers: {
      doNotAddUserTokenAuthorization: true
    }
  });
};

export const registerUser = (data, gender, seeking, terms) => {
  let registerData;
  registerData = {
    username: data.username,
    email: data.email,
    password: data.password,
    password_confirmation: data.password,
    terms_and_conditions: terms,
    profile: {
      gender: gender,
      seeking: seeking,
      city: data.city,
      zip_code: data.zip_code,
      birthday: new Date(data.birthday).toISOString().slice(0, 10)
    }
  };

  return axios.post(urls.registerUser, registerData, {
    headers: {
      doNotAddUserTokenAuthorization: true
    }
  });
};

export const registerUser2 = (data, clickId) => {
  let registerData;
  registerData = {
    username: data.username,
    email: data.email,
    password: data.password,
    password_confirmation: data.password,
    click_id: clickId,
    terms_and_conditions: 1
  };

  return axios.post(urls.registerUser, registerData, {
    headers: {
      doNotAddUserTokenAuthorization: true
    }
  });
};

export const resetUserPassword = (data, token, email) => {
  const updatePasswordData = {
    token: token,
    email: email.replace(' ', '+'),
    password: data.password,
    password_confirmation: data.password_confirmation
  };
  return axios.post(urls.resetPassword, updatePasswordData);
};

export const resendVerificationEmail = (email, userToken) => {
  return axios.post(urls.resendEmail, email, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

export const updateUserTC = (userId, terms) => {
  const updateData = {
    _method: 'PATCH',
    terms_and_conditions: terms
  };
  return axios.post(`${urls.updateUser}/${userId}`, updateData);
};

export const unsubscribe = (unsubscribeUrl) => {
  return axios.get(`${unsubscribeUrl}`);
};

export const getUserProfileWithGallery = (id) => {
  return axios.get(`${urls.userProfile}/${id}?include=galleryImages`);
};

export const requestCancelSubs = (data, userToken) => {
  return axios.post(`${urls.cancelSubscription}`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

export const sumbitContactForm = (data, userToken) => {
  return axios.post(`${urls.contact}`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};
