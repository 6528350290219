/* eslint-disable react/no-unescaped-entities */

import './GalleryImages.scss';

import React, { useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

import Button from '../../../../../../../../components/Button/Button';
import ImageN from '../../../../../../../../components/ImageN/ImageN';
import LoaderComponent from '../../../../../../../../components/LoaderCompoment/LoaderComponent';
import SuccessMessage from '../../../../../../../../components/SuccessMessage/SuccessMessage';
import { userData as userRecoil } from '../../../../../../../../recoil/atoms/userDataAtom';
import { getToken } from '../../../../../../../../services/token-service/token.service';
import { getUserData } from '../../../../../../../../services/user-service/user.service';
import { useGetUserGallery } from '../../../../../../../../V2/services/myProfile/useGetUserGallery';
import { useUpdateUserPhotos } from '../../../../../../../../V2/services/myProfile/useUpdateUserPhotos';
import PhotoAlbum from '../PhotoAlbum/PhotoAlbum';

const GalleryImages = (props) => {
  const token = getToken();
  const [user] = useRecoilState(userRecoil);
  const userId = user?.data?.profile.id;
  const [showSuccesMessage, setShowSuccesMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);
  const { mutate: saveUserPhoto } = useUpdateUserPhotos();
  const userData = getUserData();
  const userID = userData?.profile?.id;
  const albumPhotos = useRef();
  const type = 'gallery_images';
  const slider = useRef();
  const [currentImg, setCurrentImg] = useState();
  const [visible, setVisible] = useState(false);

  const { data: userGeneralData, isLoading: loader } = useGetUserGallery(userId);
  const galleryImages = userGeneralData?.gallery_images;

  let classes = ['DragAndDropZoneGallery'];

  const showModal = (index, img) => {
    setVisible(true);
    setCurrentImg(img);
    setTimeout(() => {
      slider.current.goTo(index);
    }, 200);
  };

  if (props.className) {
    classes.push(props.className);
  }

  const btnclasses = ['button-wrapper-add-new '];
  if (props.btnclasses) {
    btnclasses.push(props.btnclasses);
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData();
    const fileArray = Array.from(e.dataTransfer.files);
    const multiple = true;
    if (multiple) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        formData.append(`${type}[]`, e.dataTransfer.files[i]);
      }
    } else {
      formData.append(type, fileArray[0]);
    }
    saveUserPhoto({ userId: userID, file: fileArray, type });
  };

  const selectFile = (ref) => {
    ref.current.click();
  };

  const onBrowsedFileSelected = (e) => {
    const formData = new FormData();
    const fileArray = Array.from(e.target.files);
    const multiple = true;
    if (multiple) {
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append(`${type}[]`, e.target.files[i]);
        saveUserPhoto({ userId: userID, file: fileArray[i], type });
      }
    } else {
      formData.append(type, fileArray[0]);
      saveUserPhoto({ userId: userID, file: fileArray[0], type });
    }
  };

  return (
    <div
      className={classes.join(' ')}
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) => handleDragEnter(e)}
      onDragLeave={(e) => handleDragLeave(e)}
    >
      <SuccessMessage
        className="success-message"
        message={'Photos successfully added!'}
        showMessage={showSuccesMessage}
        setShowMessage={setShowSuccesMessage}
      />
      <SuccessMessage
        className="failure-message"
        message={showFailureMessage}
        showMessage={showFailureMessage}
        setShowMessage={setShowFailureMessage}
      />
      <PhotoAlbum
        token={token}
        galleryImages={galleryImages}
        userData={userData}
        visible={visible}
        setVisible={setVisible}
        slider={slider}
        currentImg={currentImg}
      />

      {loader ? (
        <div className="LoaderComponentWrapper">
          <LoaderComponent />
        </div>
      ) : galleryImages?.length >= 0 ? (
        <div className="GalleryPhotoContainerOuter">
          <div className="GalleryPhotoContainer">
            {galleryImages?.map((img, index) => {
              return (
                <div
                  className="ImageGalleryContainer"
                  key={index}
                  onClick={() => showModal(galleryImages.indexOf(img), img)}
                >
                  <ImageN
                    classNameImg="GalleryPhotoImg"
                    name={img.url} //thumb
                    className="GalleryPhoto"
                    type="gallery_images"
                  />
                </div>
              );
            })}
            {loader && (
              <div className="LoaderComponentWrapperGallery">
                <LoaderComponent />
              </div>
            )}
          </div>
          <div onClick={selectFile.bind(null, albumPhotos)} className="DefaultButton">
            <Button text="Add New" className={btnclasses.join(' ')} />
            <input
              type="file"
              ref={albumPhotos}
              name="albumPhotos"
              accept=".png,.jpg,.jpeg"
              hidden
              multiple={true}
              onChange={onBrowsedFileSelected}
            />
          </div>
        </div>
      ) : loader ? (
        <div className="LoaderComponentWrapperGallery">
          <LoaderComponent />
        </div>
      ) : (
        galleryImages?.length <= 0 && (
          <div className="DragAndDropTextWrapper">
            <p className={'DragAndDropText'}>"No additional photos"</p>
            <div onClick={selectFile.bind(null, albumPhotos)} className="DefaultButton">
              <Button text="Add New" className={btnclasses.join(' ')} />
              <input
                type="file"
                ref={albumPhotos}
                name="albumPhotos"
                accept=".png,.jpg,.jpeg"
                hidden
                multiple={true}
                onChange={onBrowsedFileSelected}
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};
export default GalleryImages;
